import {
  Box,
  Button,
  Card,
  Menu,
  MenuItem,
  styled,
  Checkbox,
  Typography,
  Stack,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNoteDetailsBodyJSON } from '../../slices/noteDetialsSlice'; // Import the new action
import { useCopyToClipboard } from '../../hooks/useCopyFromClipBoard';
import { getCopyContentFromValueCopy } from '../../utils';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useUser } from '@clerk/clerk-react';

const StyledTextArea = styled('textarea')(({ theme, heading }) => ({
  width: '100%',
  padding: '8px 8px 8px 2px',
  background: 'transparent',
  border: 'none',
  outline: 'none',
  resize: 'none',
  overflow: 'hidden',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  color: heading ? '#2E6FF3' : theme.palette.text.primary,
  fontWeight: heading ? 'bold' : 'normal',
  '&:disabled': {
    backgroundColor: 'transparent',
    color: heading ? '#2E6FF3' : theme.palette.text.primary,
  },
}));

const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  background: 'transparent',
  padding: '8px',
  border: 'none',
  outline: 'none',
  color: '#2E6FF3',
  fontWeight: 'bold',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
}));

const debounceDelay = 500;

const MultiContent = React.memo(
  ({
    disableHeadingEdit,
    copyChecked,
    handleExcludedHeadings,
    noteDetailType,
    value,
    itemKey: key,
    setIsEdited,
    isHighlighted
  }) => {
    const dispatch = useDispatch();
    const textAreaRef = useRef([]);
    const cardRef = useRef(null);
    const [isDelayed, setIsDelayed] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const { phrases } = useSelector((state) => state.customPhrases);
    const [noteContents, setNoteContents] = useState(
      Array.isArray(value.content) ? value.content.map((note) => note.content) : []
    );
    
    const { hasActiveSubscription } = useSelector((state) => state.stripeSubscription);
    const {user} = useUser();
    const enterpriseUser = user?.organizationMemberships[0]?.organization?.id
    const createdAt = new Date(user.createdAt);
    const currentDate = new Date();
    const diffInMs = currentDate - createdAt;
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    const isMoreThan14Days = diffInDays >= 14;

    // const copyEnable = user?.publicMetadata?.isSuperAdmin || !isMoreThan14Days || !hasActiveSubscription || enterpriseUser
    const copyEnable = user?.publicMetadata?.isSuperAdmin || !isMoreThan14Days || isMoreThan14Days || !hasActiveSubscription || hasActiveSubscription || enterpriseUser

    const adjustTextAreaHeight = useCallback(() => {
      if (textAreaRef.current) {
        textAreaRef.current.forEach((textArea, index) => {
          if (textArea) {
            textArea.style.height = 'auto'; // Reset height
            textArea.style.height = `${textArea.scrollHeight}px`; // Set new height based on content
          }
        });
      }
    }, []);

    useEffect(() => {
      adjustTextAreaHeight();
    }, [noteContents, adjustTextAreaHeight]);

    const handleOpenMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    const handlePhraseSelect = (phrase) => {
      if (textAreaRef.current) {
        const textArea = textAreaRef.current[currentIndex];
        if (textArea) {
          const text = textArea.value;
          const newText =
            text.slice(0, cursorPosition) + phrase + text.slice(cursorPosition);

          setNoteContents((prevContents) => {
            const updated = [...prevContents];
            updated[currentIndex] = newText;
            return updated;
          });
          dispatch(
            updateNoteDetailsBodyJSON({
              noteDetailType,
              index: currentIndex,
              key: value.key,
              value: newText,
            })
          );

          const newCursorPosition = cursorPosition + phrase.length;
          setTimeout(() => {
            textArea.setSelectionRange(newCursorPosition, newCursorPosition);
          }, 0);
        }
      }
      handleCloseMenu();
    };

    const handleKeyDown = (event,index) => {
      if (event.key === '*') {
        setCurrentIndex(index)
        setCursorPosition(event.target.selectionStart); // Save the cursor position
        handleOpenMenu(event); // Open the menu when * is pressed
      }
    };
    useEffect(() => {
      const delayTimer = setTimeout(() => {
        setIsDelayed(true);
      }, 3000);

      return () => clearTimeout(delayTimer);
    }, []);

    useEffect(() => {
      if (isDelayed) {
        const handler = setTimeout(() => {
          if (value && value.content) {
            setIsEdited(true);
          }
        }, debounceDelay);
        return () => clearTimeout(handler);
      }
    }, [value.heading, value.content]);

    const { copyHandler: copyContent } = useCopyToClipboard(
      getCopyContentFromValueCopy(value)
    );

    const handleChange = (index, newValue) => {
      const updatedContents = [...noteContents];
      updatedContents[index] = newValue;
      setNoteContents(updatedContents);

      dispatch(
        updateNoteDetailsBodyJSON({
          noteDetailType,
          index,
          key: value.key,
          value: newValue,
        })
      );
    };

    return (
      <Card
        ref={cardRef}
        elevation={4}
        sx={{
          margin: '10px 0px',
          padding: '5px',
          width: '100%',
          backgroundColor: 'transparent',
          border: isHighlighted ? "2px solid #2E6FF3" : "none",
        }}
      >
        <Box display='flex' alignItems='center'>
          {disableHeadingEdit ? (
            <Typography flexGrow={1} color='#2E6FF3' fontWeight='bold'>
              {value.content[0].heading}
            </Typography>
          ) : (
            <StyledInput
              disabled={disableHeadingEdit}
              onChange={(e) => {
                dispatch(
                  updateNoteDetailsBodyJSON({
                    value: e.target.value,
                    type: 'heading',
                    key,
                    noteDetailType,
                  })
                );
              }}
              heading
              value={value.heading}
            />
          )}
          <Stack direction='row' gap={1}>
            <Button
              onClick={() => {
                copyContent();
                enqueueSnackbar({
                  variant: 'success',
                  message: 'Content has been copied',
                });
              }}
              disabled={!copyEnable}
              variant='text'
            >
              Copy
            </Button>
            <Checkbox
              color='primary'
              onChange={(e) =>
                handleExcludedHeadings(
                  noteDetailType,
                  value.heading,
                  e.target.checked
                )
              }
              checked={copyChecked}
            />
          </Stack>
        </Box>
        {Array.isArray(value.content) && value.content.map((note, index) => (
          <Box
            key={note.id}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'start',
              margin: '20px',
              border: '1px solid #ccc',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              padding: '10px',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            {(note.createdAt && note.heading.trim() !== 'EKG') && (
              <Typography marginLeft='8px' color='#2E6FF3'>
                {moment
                  .utc(note.createdAt, 'MM/DD/YY HH:mm')
                  .local()
                  .format('MM/DD/YY | HH:mm')}
              </Typography>
            )}
            <StyledTextArea
              ref={(el) => {
                if (el) {
                  textAreaRef.current[index] = el;
                }
              }}
              value={noteContents[index]} // Controlled value from local state
              onKeyDown={(e) => {
                handleKeyDown(e, index);
                if (!copyEnable) {
                  if (e.ctrlKey && e.key === "c") e.preventDefault(); // Windows/Linux: Ctrl + C
                  if (e.metaKey && e.key === "c") e.preventDefault(); // macOS: Cmd + C
                  if (e.ctrlKey && e.key === "a") e.preventDefault(); // Prevent Select All (Ctrl + A)
                  if (e.metaKey && e.key === "a") e.preventDefault(); // Prevent Select All (Cmd + A)
                }
              }}
              onChange={(e) => handleChange(index, e.target.value)} // Update local state
              placeholder="Type here..."
              style={{
                width: "100%",
                height: "auto",
                resize: "none",
                overflow: "hidden",
                userSelect: !copyEnable ? "none" : "auto",
                WebkitUserSelect: !copyEnable ? "none" : "auto",
                MozUserSelect: !copyEnable ? "none" : "auto",
                msUserSelect: !copyEnable ? "none" : "auto",
              }}
              onContextMenu={(e) => {
                if (!copyEnable) e.preventDefault();
              }}
            />
          </Box>
        ))}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {phrases.map((phrase, index) => (
            <MenuItem
              key={phrase.id}
              onClick={() => handlePhraseSelect(phrase.content)}
            >
              {phrase.title}
            </MenuItem>
          ))}
        </Menu>
      </Card>
    );
  }
);

export default MultiContent;