import { Add, ArrowBack, Close, ContentCopy, Mic } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IOSSwitch } from '../../components/styled';
import TextArealayout from '../../components/TextArealayout';
import useApiRequest from '../../hooks/useHandleRequests';
import {
  clinicalDecesionTools,
  getCopyContentFromValue,
  notevalues,
  statasesvalues,
  tourStepsNoteDetails,
} from '../../utils';
import { useCopyToClipboard } from '../../hooks/useCopyFromClipBoard';
import { useDispatch, useSelector } from 'react-redux';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  resetNoteDetials,
  setnoteDetails,
} from '../../slices/noteDetialsSlice';
import UpdateNoteDialog from '../../components/dialogs/updateNoteDialog';
import { enqueueSnackbar } from 'notistack';
import { useUser } from '@clerk/clerk-react';
import { closeTutorial } from '../../slices/tutorialSlice';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { setSubscriptionData } from '../../slices/stripeSubscriptionSlice';

const enableStripe = process.env.REACT_APP_ENABLE_STRIPE;

const NoteDetails = () => {
  const {user} = useUser();
  const createdAt = new Date(user.createdAt);
  const currentDate = new Date();
  const diffInMs = currentDate - createdAt;
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  const isMoreThan14Days = diffInDays >= 14;
  const location = useLocation();
  const [isCurrentPage, setIsCurrentPage] = useState(false);
  useEffect(() => {
    const match = location.pathname.match(/^\/all-notes\/\d+$/);
    if (match) {
      setIsCurrentPage(true);
    } else {
      setIsCurrentPage(false);
    }
  }, [location]);
  const isTutorialOpen = useSelector((state) => state.tutorial.isTutorialOpen);
  const { hasActiveSubscription, subscriptionDetails } =
  useSelector((state) => state.stripeSubscription);
  const [tour, setTour] = useState(false);
  const startTour = () => {
    const driverObj = driver({
      popoverClass: 'driverjs-theme',
      showProgress: true,
      animate: true,
      prevBtnText: "Back",
      nextBtnText: "Next",
      allowClose: true,
      steps: tourStepsNoteDetails(!!followupID),
      onDestroyStarted: () => {
        setTour(false)
        localStorage.setItem(`hasSeenTour_/note-details`, 'true');
        dispatch(closeTutorial());
        driverObj.destroy();
      },
  
    });
    
    driverObj.drive();
  }
  useEffect(() => {
    if(tour){
      startTour();
    }
  }, [tour]);
  useEffect(()=>{
    const tourSeen = localStorage.getItem(`hasSeenTour_/note-details`)
    if(user?.publicMetadata?.tutorial === 0 && tourSeen === "false"){
      setTour(true)
    }
  },[])
  useEffect(()=>{
    if(isTutorialOpen && isCurrentPage){
      setTour(true)
    }
  },[isTutorialOpen])
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    summary,
    followup,
    note,
    followupID,
    status,
    summary_json,
    followup_json,
  } = useSelector((state) => state.noteDetails.noteDetails);
  const [excludedHeadings, setExcludedHeadings] = useState({
    summary_json: [],
    followup_json: [],
  });

  //using state here
  //using state here 2.0
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("Oops! It looks like you don't have an active subscription. To unlock this feature, please subscribe to our plan and enjoy full access. Click below to get subscribe!");

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleContinue = () => {
    handleCloseModal();
    navigate('/subscription');
  };
  const [selectedAction, setSelectedAction] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [loading, setLoading] = useState(false)
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState()
  const { tool } = useSelector((state) => state.clinicalTool);
  const { apiRequest: getSingleNote, loading: loadingNoteDetails } =
    useApiRequest({
      handleError: (err) => setError(err),
      handleResponse: (data) => dispatch(setnoteDetails(data)),
      showSuccessSnackbar: false,
    });
    const { apiRequest: getStripeStatus } = useApiRequest({
      handleError: (err) => {
        console.log(err);
      },
      handleResponse: (data) => {
        dispatch(
          setSubscriptionData({
            hasActiveSubscription: data.hasActiveSubscription,
            subscriptionDetails: data.subscription,
          })
        );
      },
      showSuccessSnackbar: false,
    });

    useEffect(() => {
      if(status === 'COMPLETE' && summary !== null){
        setLoading(false)
        getSingleNote(`/v2/note/${id}`);
      }
      else{
        setLoading(true)
      }
    }, [status])

  const handleClose = () => {
    setSelectedAction(null);
    getSingleNote(`/v2/note/${id}`);
  };

  const handleExcludedHeadings = (key, heading, checked) => {
    if (checked) {
      setExcludedHeadings((headings) => ({
        ...headings,
        [key]: headings[key].filter((item) => item !== heading),
      }));
    } else {
      setExcludedHeadings((headings) => ({
        ...headings,
        [key]: [...headings[key], heading],
      }));
    }
  };

  const getCopyAllData = () => {
    let content = 'Note:\n';
  
    // Loop through all properties in summary_json
    if (summary_json) {
      content += Object.values(summary_json).reduce((prev, value) => {
        return prev + getCopyContentFromValue(value) + '\n\n';
      }, '');
    }
  
    // Loop through all properties in followup_json
    if (followup_json) {
      content += '\nDisposition:\n';
      content += Object.values(followup_json).reduce((prev, value) => {
        return prev + getCopyContentFromValue(value) + '\n\n';
      }, '');
    }
  
    return content;
  };
  
  const getCopySelectedData = () => {
    let content = 'Note:\n';
  
    // Loop through summary_json and exclude headings if needed
    if (summary_json) {
      content += Object.values(summary_json).reduce((prev, value) => {
        return (
          prev +
          `${
            excludedHeadings.summary_json.includes(value.heading)
              ? ''
              : getCopyContentFromValue(value)
          }\n`
        );
      }, '');
    }
  
    // Loop through followup_json and exclude headings if needed
    if (followup_json) {
      content += '\n\n\nDisposition:\n';
      content += Object.values(followup_json).reduce((prev, value) => {
        return (
          prev +
          `${
            excludedHeadings.followup_json.includes(value.heading)
              ? ''
              : getCopyContentFromValue(value)
          }\n`
        );
      }, '');
    }
  
    return content;
  };
  
  const { copyHandler: copyAll } = useCopyToClipboard(getCopyAllData());
  const { copyHandler: copySelected } = useCopyToClipboard(
    getCopySelectedData()
  );

  useEffect(() => {
    getSingleNote(`/v2/note/${id}`);
    if(status !== 'COMPLETE'){
      setLoading(true)
    }
    // getStripeStatus(`/v2/stripe/status`)
    return () => {
      dispatch(resetNoteDetials());
    };
  }, []);
  useEffect(() => {
    if (!tool) {
      const interval = setInterval(() => {
        getSingleNote(`/v2/note/${id}`);
        if(status !== 'COMPLETE'){
          setLoading(true)
        }
        return () => {
          dispatch(resetNoteDetials());
        };
      }, 10000);
      // 60 sec delay 
      return () => clearInterval(interval);
    }
  }, [summary_json, note, tool]);

  useEffect(() => {
    if (loading && followupID && status === 'COMPLETE') {
      const timer = setTimeout(() => {
        getSingleNote(`/v2/note/${id}`);
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [loading, followupID]);

  const noteValue = localStorage.getItem('noteType');
  useEffect(() => {
    let timeout;
    if (noteValue === 'EKG' || noteValue === 'Care Update') {
      setShowLoader(true);
      timeout = setTimeout(() => {
        setShowLoader(false);
        getSingleNote(`/v2/note/${id}`);
        localStorage.removeItem('noteType');
      }, 8000);
    }
      return () => clearTimeout(timeout);
  }, [noteValue]);

  const getErrorMessage = (status) => {
    switch (status) {
      case "ERROR_TRANSCRIPTION":
        return "An error occurred during transcription. Please try again.";
      case "ERROR_EMPTY_JSON":
        return "The transcription resulted in empty data. Please verify the input and try again.";
      case "ERROR":
        return "Oops! Something went wrong. Please try again later.";
      default:
        return "Oops! Something went wrong. Please try again later.";
    }
  };

  if (error) {
    return (
      <Stack
        justifyContent="center"
        marginTop="30px"
        gap={2}
        alignItems="center"
        sx={{
          backgroundColor: '#fdecea',
          border: '1px solid #f5c6cb',
          borderRadius: '8px',
          padding: '20px',
          maxWidth: '400px',
          margin: '30px auto',
        }}
      >
        <ErrorOutlineIcon sx={{ color: '#f44336', fontSize: '36px' }} />
        <Typography
          variant="body1"
          sx={{
            color: '#721c24',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          {error.error}
        </Typography>
      </Stack>
    );
  }

  const userProfession = user?.publicMetadata?.profession;
  const enterpriseUser = user?.organizationMemberships[0]?.organization?.id
  // const copyEnable = user?.publicMetadata?.isSuperAdmin || !isMoreThan14Days || hasActiveSubscription || enterpriseUser;
  const copyEnable = user?.publicMetadata?.isSuperAdmin || !isMoreThan14Days || isMoreThan14Days || !hasActiveSubscription || hasActiveSubscription || enterpriseUser

  return (
    <React.Fragment>
      <Stack gap={2} direction='row' marginBottom='15px'>
        <Button
          id='backToDashboard'
          sx={{ color: '#2E6FF3' }}
          onClick={() => {
            navigate('/all-notes');
            dispatch(resetNoteDetials());
          }}
          variant='text'
          startIcon={<ArrowBack />}
        >
          Back to Dashboard
        </Button>
        {userProfession === "Emergency Medicine" && 
        Object.values(notevalues).map((noteValue,index) => (
          <Button
            id={`noteValue${index}`}
            startIcon={<Mic />}
            color='error'
            size='small'
            disabled={
              followup_json ||
              !summary_json ||
              summary_json.length <= 0 ||
              status === 'ERROR_EMPTY_JSON' ||
              status === 'ERROR_TRANSCRIPTION' ||
              status === 'ERROR'
            }
            disableElevation
            onClick={() => setSelectedAction(noteValue)}
            variant='contained'
          >
            {noteValue.label}
          </Button>
        ))}
      </Stack>
        {showLoader && (
          <Stack
            justifyContent="center"
            marginTop="30px"
            gap={2}
            alignItems="center"
          >
            <CircularProgress />
            <Typography variant="body2">{`${noteValue} note processing...`}</Typography>
          </Stack>
        )}
        {!showLoader ? (
        loading ? (
          status === "ERROR_TRANSCRIPTION" || status === "ERROR_EMPTY_JSON" || status === "ERROR" ? (
            <Stack justifyContent="center" marginTop="30px" gap={2} alignItems="center">
              <Typography variant="h6" color="error" textAlign="center">
                {getErrorMessage(status)}
              </Typography>
            </Stack>
            ) : (
              <Stack
                justifyContent="center"
                marginTop="30px"
                gap={2}
                alignItems="center"
              >
                <CircularProgress />
                <Typography variant="body2">
                  {statasesvalues[status]}
                </Typography>
              </Stack>
            )
          ) : (
            <>
              <Stack direction="row" gap={2} alignItems="center" marginBottom="15px">
                <Typography
                  component="span"
                  color="#202224"
                  fontSize="32px"
                  fontWeight={700}
                >
                  {note?.patient?.innitials} |{" "}
                  {note?.patient?.sex && note?.patient?.sex[0]} |{" "}
                  {note?.patient?.age} {note?.patient?.ageType}
                </Typography>
                <Chip
                  sx={{ borderRadius: "8px", padding: "10px", bgcolor: "#2E6FF3" }}
                  id="copyAll"
                  clickable
                  variant="filled"
                  icon={<ContentCopy />}
                  label="Copy All"
                  color="primary"
                  onClick={() => {
                    if (enableStripe === "true") {
                      if (!copyEnable) {
                        handleOpenModal();
                      } else {
                        copyAll();
                        enqueueSnackbar({
                          variant: "success",
                          message: "Content has been copied",
                        });
                      }
                    } else {
                      copyAll();
                      enqueueSnackbar({
                        variant: "success",
                        message: "Content has been copied",
                      });
                    }
                  }}
                />
                <Chip
                  sx={{ borderRadius: "8px", padding: "10px", bgcolor: "#2E6FF3" }}
                  clickable
                  id="copySelected"
                  variant="filled"
                  icon={<ContentCopy />}
                  label="Copy Selected"
                  color="primary"
                  onClick={() => {
                    if (enableStripe === "true") {
                      if (!copyEnable) {
                        handleOpenModal();
                      } else {
                        copySelected();
                        enqueueSnackbar({
                          variant: "success",
                          message: "Selected Content has been copied",
                        });
                      }
                    } else {
                      copySelected();
                      enqueueSnackbar({
                        variant: "success",
                        message: "Selected Content has been copied",
                      });
                    }
                  }}
                />
                <Chip
                  sx={{ borderRadius: "8px", padding: "10px", bgcolor: "#2E6FF3" }}
                  clickable
                  id="unSelect"
                  variant="filled"
                  icon={<Close />}
                  label="Unselect All"
                  color="primary"
                  onClick={() => {
                    setExcludedHeadings((headings) => ({
                      ...headings,
                      summary_json: summary_json
                        ? Object.values(summary_json).map(
                            (summary) => summary.heading
                          )
                        : headings.summary_json,
                      followup_json: followup_json
                        ? Object.values(followup_json).map(
                            (followup) => followup.heading
                          )
                        : headings.followup_json,
                    }));
                  }}
                />
              </Stack>
              <Grid container component="main" spacing={2}>
                {
                  <Grid size={{ lg: 6, md: 6, sm: 12, xs: 12 }} id="noteDetails">
                    {tool &&
                      (clinicalDecesionTools
                        .find((clincalTool) => clincalTool.tool === tool)
                        .componenet({
                          sex: note?.patient?.sex,
                          age: note?.patient?.age,
                          ageType: note?.patient?.ageType,
                        }) ||
                        null)}
                    <TextArealayout
                      excludedHeadings={excludedHeadings.summary_json}
                      handleExcludedHeadings={handleExcludedHeadings}
                      id={note?.id}
                      title="Note"
                      noteKey="summary_json"
                      copyNoteKey="summary"
                    />
                  </Grid>
                }
                {!(followup === null || followup === undefined) && (
                  <Grid size={{ lg: 6, md: 6, sm: 12, xs: 12 }} id="disposition">
                    <TextArealayout
                      excludedHeadings={excludedHeadings.followup_json}
                      handleExcludedHeadings={handleExcludedHeadings}
                      id={followupID}
                      title="Disposition"
                      noteKey="followup_json"
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )
        ) : null}
      <UpdateNoteDialog
        data={note}
        type={selectedAction}
        handleClose={handleClose}
      />
      {/* Modal (Dialog) */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            padding: '20px',
            alignItems: "center",
            borderRadius: '16px',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)',
            minWidth: '400px',
            bgcolor: '#f9f9f9',
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem', color: '#2E6FF3' }}>
          Subscribe Now!
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontSize: '1rem', color: '#555', marginBottom: '20px' }}>
            {modalMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            sx={{
              color: '#fff',
              bgcolor: 'gray',
              '&:hover': { bgcolor: '#b5b5b5' },
              padding: '8px 16px',
              borderRadius: '8px',
              textTransform: 'none',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleContinue}
            sx={{
              color: '#fff',
              bgcolor: '#2E6FF3',
              '&:hover': {
                bgcolor: 'linear-gradient(45deg, #4CAF50 30%, #2E6FF3 90%)',
              },
              padding: '8px 16px',
              borderRadius: '8px',
              textTransform: 'none',
            }}
          >
            Buy
          </Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
};

export default NoteDetails;
