import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  Typography,
  Select as MuiSelect,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Avatar,
  ListSubheader,
  Stack,
  CircularProgress,
} from "@mui/material";
import { ArrowRightAlt, ArrowRight as ArrowRightIcon } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useUser, useSession } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { professionGroups } from "../../utils";
import useApiRequest from "../../hooks/useHandleRequests";

const formatGroupLabel = (data) => (
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <span>{data.label}</span>
    <span
      style={{
        backgroundColor: "#e0e0e0",
        padding: "0 8px",
        borderRadius: "12px",
        fontSize: "12px",
        color: "#1565c0",
      }}
    >
      {data.options.length}
    </span>
  </Box>
);
const paths = [
  '/createNote',
  '/all-notes',
  '/custom-note-template',
  '/note-details',
];


const Onboarding = () => {
  const { user } = useUser();
  const enterpriseUser = user?.organizationMemberships[0]?.organization?.id
  const [profession, setProfession] = useState(enterpriseUser ? "Emergency Medicine" : "");
  const [role, setRole] = useState("physician");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const navigate = useNavigate();

  const { apiRequest, loading} = useApiRequest({
    handleResponse: ()=>{
        
    },
    handleError: (err) => console.log(err),
    successMessage: "User has been on-boarded"
  });

  const handleUpdate = async () => {
    await apiRequest('/v2/user/onboarding','post', {
      profession, role
    })
    setTimeout(()=>{
      navigate('/all-notes')
      window.location.reload();
    },1000)
    paths.forEach((path) => localStorage.setItem(`hasSeenTour_${path}`, 'false'));
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Grid container justifyContent="center">
        <Avatar
          alt="Dr Haydar Logo"
          src="/images/logo-black.webp"
          sx={{ width: 100, height: 100 }}
        />
      </Grid>
      <Box my={2}>
        <Typography variant="h4" align="center">
          Welcome!
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          What&apos;s your medical speciality?
        </Typography>
      </Box>
      <Box my={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Select Profession</InputLabel>
          <MuiSelect
            value={profession}
            disabled={loading || enterpriseUser}
            onChange={(e) => {
              setProfession(e.target.value)
            }}
            label="Select Profession"
          >
            <ListSubheader>{professionGroups[0].label}</ListSubheader>
            {
              professionGroups[0].options.map(option => <MenuItem value={option.value}>{option.label}</MenuItem>)
            }
            <ListSubheader>{professionGroups[1].label}</ListSubheader>
            {
              professionGroups[1].options.map(option => <MenuItem value={option.value}>{option.label}</MenuItem>)
            }
          </MuiSelect>
        </FormControl>
        {/* <TextField
          disabled={loading}
          fullWidth
          margin="normal"
          variant="outlined"
          label="Invitation Code"
          value={referrer}
          onChange={(e) => setReferrer(e.target.value)}
        /> */}
      </Box>
      {!enterpriseUser && (
        <>
      <Typography variant="text" mt={1} align="start">
          Select level:
        </Typography>
        <Box mt={1} mb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
        <FormControlLabel
            control={
              <Checkbox
                checked={role === "app"}
                onChange={() => setRole(role === "app" ? "" : "app")}
              />
            }
            label="Advanced Practice Provider"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={role === "physician"}
                onChange={() => setRole(role === "physician" ? "" : "physician")}
              />
            }
            label="Physician"
          />
        </Box>
        </>
      )}
      <FormControlLabel
        disabled={loading}
        control={
          <Checkbox
            checked={acceptedTerms}
            onChange={() => setAcceptedTerms(!acceptedTerms)}
          />
        }
        label={
          <>
            Accept <a href="https://drh.ai/legal" style={{ color: "#2E6FF3", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">privacy policy & terms of service</a>. By checking this box you confirm that any personal data provided is correct.
          </>
        }
        sx={{ marginBottom: 2 }}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        disabled={!profession || !acceptedTerms || loading}
        onClick={handleUpdate}
        endIcon={<ArrowRightAlt />}
      >
        Continue
      </Button>
      {
        loading && <Stack alignItems="center" margin="15px 0px" justifyContent="center">
        <CircularProgress />
        </Stack>
      }
    </Container>
  );
};

export default Onboarding;
