// src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import notesReducer from './slices/notesSlice';
import customPhraseReducer from './slices/customPhraseSlice';
import customNotesReducer from './slices/customNotes';
import noteDetailsReducer from './slices/noteDetialsSlice';
import clinicalToolReducer from './slices/clinicalToolSlice';
import tutorialReducer from './slices/tutorialSlice';
import promptsSliceReducer from './slices/promptsSlice';
import stripeSubscriptionReducer from './slices/stripeSubscriptionSlice';

const store = configureStore({
  reducer: {
    notes: notesReducer,
    stripeSubscription: stripeSubscriptionReducer,
    customPhrases: customPhraseReducer,
    noteTemplates: customNotesReducer,
    noteDetails: noteDetailsReducer,
    clinicalTool: clinicalToolReducer,
    tutorial: tutorialReducer,
    prompts: promptsSliceReducer,
  },
});

export default store;
